<!--
 * @Author: hwu
 * @Date: 2020-10-23 09:35:11
 * @Description: Dialog提示框组件，带遮罩层。content支持html。
 * @LastEditTime: 2024-11-06 16:43:07
-->

<!--
 * @param title 标题
 * @param content 内容
 * @param showLeftBtn 是否显示左边按钮
 * @param showRightBtn 是否显示右边按钮
 * @param leftBtnText 左边按钮文本
 * @param rightBtnText 右边按钮文本
 * @event onLeftClick 左边按钮点击触发
 * @event onRightClick 右边按钮点击触发
 * @event onBackdropClick 背景遮罩点击触发
 * @slot  body 自定义提示内容
 -->

<template>
  <div class="wh-dialog-wrapper" @touchmove.stop>
    <transition name="wh-dialog">
      <div class="wh-dialog-box" v-if="show">
        <div class="wh-dialog-header" v-if="showTitle">
          <slot name="title">
            <div>{{ title }}</div>
          </slot>
        </div>
        <div class="wh-dialog-body" :class="{ 'no-padding': noPadding }" :style="{ minHeight: minHeight }">
          <div class="custom_dialog-body_text" v-if="content != ''">{{content}}</div>
          <slot name="body" v-else></slot>
        </div>
        <div class="wh-dialog-footer">
          <button class="wh-dialog-btn left-btn" :class="leftBtnClass"  @click="onLeftClick" v-if="showLeftBtn">{{leftBtnText}}</button>
          <button class="wh-dialog-btn right-btn" @click="onRightClick" v-if="showRightBtn">{{rightBtnText}}</button>
          <slot name="footer" v-if="!showLeftBtn && !showRightBtn"></slot>
        </div>
      </div>
    </transition>
    <transition name="wh-backdrop">
      <div class="wh-dialog-backdrop" v-if="show" @click="onBackdropClick"></div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'dialog-box',
  props: {
    show: { type: Boolean, required: true, default: false },
    showTitle: { type: Boolean, required: false, default: true },
    title: { type: String, required: false, default: '温馨提示' },
    content: { type: String, required: false, default: '' },
    minHeight: { type: String, required: false, default: '60px' },
    noPadding: { type: Boolean, required: false, default: false },
    showLeftBtn: { type: Boolean, required: false, default: false },
    showRightBtn: { type: Boolean, required: false, default: true },
    leftBtnText: { type: String, required: false, default: '取消' },
    leftBtnClass: { type: String, required: false, default: '' },
    rightBtnText: { type: String, required: false, default: '确定' }
  },
  methods: {
    onBackdropClick: function (e) {
      this.$emit('onBackdropClick', e)
    },
    onLeftClick (e) {
      this.$emit('onLeftClick', e)
    },
    onRightClick (e) {
      this.$emit('onRightClick', e)
    }
  }
}
</script>
<style lang="scss" scoped>
.wh-dialog-box {
  position: fixed;
  top: 50%;
  left: 375px;
  width: 638px;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  background-color: $color-white;
  border-radius: 24px;
  -webkit-user-select: none;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  z-index: 2001;
  .wh-dialog-header {
    width: 100%;
    padding: 48px 24px  24px  24px ;
    line-height: 44px ;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: $color-text-main;
  }
  .wh-dialog-body {
    width: 100%;
    max-height: 720px;
    min-height: 120px;
    // line-height: 36px;
    padding: 24px  40px;
    text-align: center;
    box-sizing: border-box;
    overflow-y: auto;
    &.no-padding {
      padding: 0;
    }
    // -webkit-overflow-scrolling: touch;
    .custom_dialog-body_text {
      line-height: 36px;
      text-align: center;
    }
    /deep/ p {
      margin: 10px 0;
    }
  }
  .wh-dialog-footer {
    position: relative;
    display: flex;
    width: 100%;
    padding: 10px 0;
    align-items: center;
    background-color: $color-white;
    border-top: 1px solid #eee;
    .wh-dialog-btn {
      flex: 1 1 50%;
      height: 80px;
      line-height: 80px;
      text-align: center;
      font-size: 32px;
      color: $color-text-sub;
      background-color: transparent;
      border: none;
      box-sizing: border-box;
      &:focus {
        outline: 0;
      }
      &.left-btn {
        color: $color-text-sub;
      }
      &.right-btn {
        color: $color-primary;
      }
    }
  }
}
.wh-dialog-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 2000;
}
.wh-dialog-enter-active,
.wh-dialog-leave-active {
  transition: opacity 0.5s;
}
.wh-dialog-enter,
.wh-dialog-leave-to {
  opacity: 0;
}
.wh-backdrop-enter-active,
.wh-backdrop-leave-active {
  transition: opacity 1s;
}
.wh-backdrop-enter,
.wh-backdrop-leave-to {
  opacity: 0;
}
</style>
